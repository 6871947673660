import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import { Link } from '@mui/material';

export const Page = styled('div')`
  align-items: start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  padding: 30px;
  width: 100vw;

  @media (max-width: ${device.mobileXL}) {
    height: 100dvh;
    padding: 20px;
  }
`;

export const Logo = styled('img')`
  cursor: pointer;
  height: 22px;
`;

export const CardSection = styled('div')`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
`;

export const BottomSection = styled('div')`
  display: flex;
  gap: 30px;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: ${device.mobileL}) {
    justify-content: center;
  }
`;

export const BottomButton = styled('div')`
  align-items: center;
  color: ${colours.neutralCool[800]};
  cursor: pointer;
  display: flex;
  gap: 5px;

  svg path {
    stroke: ${colours.neutralCool[800]};
  }

  &:hover {
    p {
      color: ${colours.primary[500]};
    }
    svg path {
      stroke: ${colours.primary[500]};
    }
  }
`;

export const HelpLink = styled(Link)`
  color: ${colours.neutralCool[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-decoration-color: ${colours.neutralCool[800]};
`;
