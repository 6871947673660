import React from 'react';
import Markdown from 'mui-markdown';
import PropTypes from 'prop-types';
import * as Styled from 'components/_pageLayout/_styles/StyledMarkdown.style';

const StyledMarkdown = ({ content }) => (
  <Styled.MarkdownContainer>
    <Markdown>{content}</Markdown>
  </Styled.MarkdownContainer>
);

export default StyledMarkdown;

StyledMarkdown.propTypes = {
  content: PropTypes.any,
};
