import React, { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as Text from 'components/_styles/Text.style';
import * as Styled from 'components/pageECoach/_styles/Report.style';

export default function SuggestionItemDetails({ item: suggestion }) {
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture('suggestion_item_loaded');
  }, [posthog]);

  if (!suggestion) {
    return <h1>No suggestion set ?</h1>;
  }

  return (
    <>
      <Text.P2>{suggestion?.description}</Text.P2>
      <Styled.Content>
        <Text.P2Semibold>{t('Why this matters')}</Text.P2Semibold>
        <Styled.List>
          {suggestion?.why_it_matters.map((pro) => (
            <li key={pro}>{pro}</li>
          ))}
        </Styled.List>
      </Styled.Content>
      <Styled.Content>
        <Text.P2Semibold>{t('Potential challenges')}</Text.P2Semibold>
        <Styled.List>
          {suggestion?.potential_challenges.map((con) => (
            <li key={con}>{con}</li>
          ))}
        </Styled.List>
      </Styled.Content>
    </>
  );
}

SuggestionItemDetails.propTypes = {
  item: PropTypes.object.isRequired,
};
