import React, { useEffect, useState } from 'react';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

// assets
import LogoText from 'assets/logoText.svg';
import { Communication, General } from 'untitledui-js';

// components
import CodeCard from 'components/pageLogin/CodeCard';
import EmailCard from 'components/pageLogin/EmailCard';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';

// helpers
import resetApp from 'helpers/resetApp';
import supportLink from 'helpers/supportLink';

// styles
import * as Styled from 'components/pageLogin/_styles/Page.style';
import * as Text from 'components/_styles/Text.style';

export default function Login() {
  const { t } = useTranslation('pageLogin');
  const [language, setLanguage] = useState('en');

  const accessCodePage = window.location.pathname === '/login/access-code';

  const toggleLanguage = () => {
    const newLang = language === 'en' ? 'fr' : 'en';
    setLanguage(newLang);
    i18n.changeLanguage(newLang);
  };

  const helpClick = () => window.open(supportLink(language), '_blank');

  useEffect(() => {
    localStorage.removeItem('authorization');
  }, []);

  return (
    <Styled.Page>
      <Styled.Logo onClick={resetApp} src={LogoText} />
      <Styled.CardSection>
        <ErrorBoundary>
          {accessCodePage ? <CodeCard /> : <EmailCard />}
        </ErrorBoundary>
      </Styled.CardSection>
      <Styled.BottomSection>
        <Styled.BottomButton onClick={toggleLanguage}>
          <Communication.MessageTextSquare01 height={24} />
          <Text.P2Semibold>
            {language === 'en' ? 'Français' : 'English'}
          </Text.P2Semibold>
        </Styled.BottomButton>
        <Styled.BottomButton onClick={helpClick}>
          <General.HelpSquare height={24} />
          <Text.P2Semibold>{t('page.help')}</Text.P2Semibold>
        </Styled.BottomButton>
      </Styled.BottomSection>
    </Styled.Page>
  );
}
