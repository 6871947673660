import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// constants
import routesConfig from 'constants/routesConfig.json';

// styles
import colours from 'constants/colours';
import * as Styled from 'components/pageLogin/_styles/ErrorMessage.style';
import * as Text from 'components/_styles/Text.style';

export default function ErrorMessage({ action, error }) {
  const { t } = useTranslation('pageLogin');
  const history = useHistory();

  const [timeLeft, setTimeLeft] = useState(60);

  useEffect(() => {
    let intervalId;

    if (error === 'too many attempts' && timeLeft > 0) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalId);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return function cleanup() {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [error, timeLeft]);

  const resendEmail = useCallback(() => {
    history.push({ pathname: `${routesConfig.LOGIN_PAGE}` });
  }, [history]);

  if (error === 'invalid email')
    return (
      <Styled.Error>
        <Styled.ErrorMessage>
          {t('errorMessages.invalidEmailLabel')}
        </Styled.ErrorMessage>
        <Styled.ErrorMessage>
          <Trans
            i18nKey="errorMessages.invalidEmailAction"
            components={[
              <Link
                key="0"
                href="mailto:info@nurau.com"
                underline="none"
                target="_blank"
              />,
            ]}
            ns="pageLogin"
          />
        </Styled.ErrorMessage>
      </Styled.Error>
    );

  if (error === 'invalid code')
    return (
      <Styled.Error>
        <Styled.ErrorMessage>
          {t('errorMessages.expiredCodeLabel')}
        </Styled.ErrorMessage>
        <Styled.ErrorMessage
          onClick={resendEmail}
          style={{ color: colours.primary[400], cursor: 'pointer' }}
        >
          {t('errorMessages.expiredCodeAction')}
        </Styled.ErrorMessage>
        <Styled.ErrorMessage>{t('errorMessages.or')}</Styled.ErrorMessage>
        <Styled.ErrorMessage
          onClick={action}
          style={{ color: colours.primary[400], cursor: 'pointer' }}
        >
          {t('errorMessages.reEnterCode')}
        </Styled.ErrorMessage>
      </Styled.Error>
    );

  if (error === 'too many attempts')
    return (
      <Styled.Error>
        <Styled.ErrorMessage>
          {t('errorMessages.tooManyAttemptsLabel')}
        </Styled.ErrorMessage>
        {timeLeft === 0 ? (
          <Styled.ErrorMessage
            onClick={resendEmail}
            style={{ color: colours.primary[400], cursor: 'pointer' }}
          >
            {t('errorMessages.expiredCodeAction')}
          </Styled.ErrorMessage>
        ) : (
          <Styled.ErrorMessage>
            {t('errorMessages.tooManyAttemptsAction', { timeLeft })}
          </Styled.ErrorMessage>
        )}
      </Styled.Error>
    );

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <Text.P2Semibold>{t('errorMessages.missingEmailLabel')}</Text.P2Semibold>
      <Styled.Resend onClick={resendEmail}>
        {t('errorMessages.missingEmailAction')}
      </Styled.Resend>
    </div>
  );
}

ErrorMessage.propTypes = {
  action: PropTypes.func,
  error: PropTypes.string,
};
