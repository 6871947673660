import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import TextField from '@mui/material/TextField';
import * as Cards from 'components/_styles/Cards.style';

export const CardSection = styled('div')`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
`;

export const Card = styled(Cards.BasicCard)`
  gap: 30px;
  max-width: 450px;
  padding: 60px;

  @media (max-width: ${device.mobileL}) {
    border-radius: 30px;
    padding: 30px;
    text-align: center;
  }
`;

export const Header = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FormInput = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const VerificationWrapper = styled.div`
  .vi__character {
    background-color: ${colours.neutralWarm[50]};
    border-color: ${colours.neutralWarm[300]};
    border-radius: 8px;
    color: ${colours.neutralCool[800]};
    font-family: 'Roboto', sans-serif;
    font-size: 23px;
  }
  .vi__container {
    justify-content: space-between;
    width: 100%;
  }
`;

export const Input = styled(TextField)`
  background-color: ${colours.neutralWarm[50]};
  border: none;
  border-radius: 50px;

  fieldset {
    border: none;
    border-radius: 50px;
  }

  input {
    font-size: 16px;
  }

  label.Mui-focused,
  label.MuiFormLabel-filled {
    top: -10px;
  }

  /* For Webkit browsers like Chrome, Safari */
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* For Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
