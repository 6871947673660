import React, { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import { General } from 'untitledui-js';

// context
import { useUser } from 'contexts/UserContext';

// hooks
import useApi from 'hooks/useApi';

// style
import colours from 'constants/colours';
import * as Text from 'components/_styles/Text.style';
import * as TopbarStyled from 'components/navbar/_styles/Topbar.style';

export default function Logout() {
  const { data: logoutCall, callApi } = useApi();
  const posthog = usePostHog();
  const { data: user } = useUser();
  const { t } = useTranslation('navbar');

  const handleLogout = () => {
    callApi('/logout/', 'post', {});
    localStorage.removeItem('authorization');
    posthog?.capture('user_clicks_logout');
    posthog?.reset();
  };

  useEffect(() => {
    if (logoutCall.status === 'success' || logoutCall.status === 'error')
      window.location.reload();
  }, [logoutCall]);

  return (
    <TopbarStyled.Logout onClick={handleLogout}>
      <General.Logout01
        size={20}
        stroke={colours.neutralCool[500]}
        strokeWidth={1.5}
      />
      <TopbarStyled.LogoutText>
        <Text.P1Semibold style={{ color: colours.neutralCool[500] }}>
          {t('logout')}
        </Text.P1Semibold>
        <Text.P1 style={{ color: colours.neutralCool[500] }}>
          {user.first_name}
        </Text.P1>
      </TopbarStyled.LogoutText>
    </TopbarStyled.Logout>
  );
}

Logout.propTypes = {
  getUser: PropTypes.func,
};
