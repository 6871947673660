// eslint-disable-next-line import/prefer-default-export
import { useEffect, useState } from 'react';
import useWebsocketSetup from './useWebsocketSetup';

export const LoadingStatus = Object.freeze({
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
  IDLE: 'idle',
});

// eslint-disable-next-line import/prefer-default-export
export const useEcoachItemsConsumer = (sessionId, consumerEndpoint) => {
  const [context, setContext] = useState({
    status: LoadingStatus.IDLE,
    data: null,
  });
  const [items, setItems] = useState({
    status: LoadingStatus.IDLE,
    data: null,
  });
  const [ragSummary, setRagSummary] = useState({
    status: LoadingStatus.IDLE,
    data: null,
  });
  const [selectedItem, setSelectedItem] = useState({
    status: LoadingStatus.IDLE,
    data: null,
  });

  const { sendJsonMessage, lastJsonMessage, readyState } =
    useWebsocketSetup(consumerEndpoint);

  useEffect(() => {
    if (lastJsonMessage === null) {
      return;
    }

    const { action, data } = lastJsonMessage;

    if (action === 'list') {
      setContext({
        status: LoadingStatus.LOADED,
        data: data.context,
      });
      setItems({
        status: LoadingStatus.LOADED,
        data: data.items,
      });
      setRagSummary({
        status: LoadingStatus.LOADED,
        data: data.rag_summary,
      });
    } else if (action === 'get') {
      setSelectedItem({ status: LoadingStatus.LOADED, data });
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    if (readyState === WebSocket.OPEN && sessionId) {
      setContext({ status: LoadingStatus.LOADING, data: null });
      setItems({ status: LoadingStatus.LOADING, data: null });
      setRagSummary({ status: LoadingStatus.LOADING, data: null });

      sendJsonMessage({
        action: 'list',
        request_id: Date.now(),
        pk: sessionId,
      });
    }
  }, [readyState, sendJsonMessage, sessionId]);

  const selectItem = (itemId) => {
    if (!itemId) {
      setSelectedItem({ status: LoadingStatus.IDLE, data: null });
      return;
    }

    setSelectedItem({
      status: LoadingStatus.LOADING,
      data: { id: itemId },
    });

    if (readyState === WebSocket.OPEN) {
      sendJsonMessage({
        action: 'get',
        request_id: Date.now(),
        pk: sessionId,
        item_id: itemId,
      });
    }
  };

  return { context, items, selectedItem, selectItem, ragSummary };
};
