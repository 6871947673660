import React from 'react';
import PropTypes from 'prop-types';

// assets
import { Communication, General, Shapes } from 'untitledui-js';

// styles
import * as Styled from 'components/pageDemoRegistration/_styles/SuggestionCard.style';
import * as Text from 'components/_styles/Text.style';

export default function SuggestionCards({ t }) {
  const suggestions = [
    {
      title: t('Create a conversation plan'),
      description: t(
        'Identify key talking points and plan for most likely responses.'
      ),
    },
    {
      title: t('Check out resources'),
      description: t('Here are some resources offered by your workplace.'),
    },
    {
      title: t('Try these exercises'),
      description: t(
        'These have proven helpful to other managers in similar situations.'
      ),
    },
  ];

  const getSuggestion = (suggestion) => (
    <Styled.SuggestionCard key={suggestion.title}>
      <Styled.Content>
        <Text.P2Semibold>{suggestion.title}</Text.P2Semibold>
        <Text.P2>{suggestion.description}</Text.P2>
      </Styled.Content>
      <Styled.ActionArea>
        <Styled.RobotAction>
          <Communication.MessageCircle01 />
        </Styled.RobotAction>
        <Styled.StarAction>
          <Shapes.Star01 />
        </Styled.StarAction>
        <Styled.XAction>
          <General.XClose />
        </Styled.XAction>
      </Styled.ActionArea>
    </Styled.SuggestionCard>
  );

  return (
    <Styled.Suggestions>
      {suggestions.map((suggestion) => getSuggestion(suggestion))}
    </Styled.Suggestions>
  );
}

SuggestionCards.propTypes = {
  t: PropTypes.func.isRequired,
};
