import React, { useCallback, useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// components
import ErrorMessage from 'components/pageLogin/ErrorMessage';

// constants
import routesConfig from 'constants/routesConfig.json';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/pageLogin/_styles/Card.style';
import * as Text from 'components/_styles/Text.style';

export default function EmailCard() {
  const { data, callApi } = useApi();
  const { t } = useTranslation('pageLogin');
  const history = useHistory();
  const posthog = usePostHog();
  const [email, setEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const handleEmailChange = (event) => setEmail(event.target.value);

  useEffect(() => {
    if (emailSubmitted && data.statusCode === 400) setInvalidEmail(true);
    if (emailSubmitted && data.status === 'success')
      history.push({
        pathname: `${routesConfig.ACCESS_CODE_PAGE}`,
        state: {
          email,
        },
      });
  }, [data, email, emailSubmitted, history]);

  const submitEmail = useCallback(() => {
    callApi('/login/', 'post', { email });
    setEmailSubmitted(true);
    posthog?.capture('user_requested_login_link');
  }, [callApi, email, posthog]);

  return (
    <Styled.Card>
      <Styled.Header>
        <Text.H2>{t('emailCard.heading')}</Text.H2>
        <Text.P2Semibold>{t('emailCard.subheading')}</Text.P2Semibold>
      </Styled.Header>
      <Styled.FormInput>
        <Styled.Input
          autoComplete="email"
          autoFocus
          fullWidth
          id="email"
          InputLabelProps={{
            style: { color: 'grey', fontSize: 16 },
          }}
          label={t('emailCard.inputLabel')}
          margin="normal"
          name="email"
          onChange={handleEmailChange}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              if (email.length !== 0) submitEmail();
              ev.preventDefault();
            }
          }}
          value={email}
        />
        {email.length !== 0 ? (
          <Buttons.Primary onClick={submitEmail}>
            {t('emailCard.submitButtonLabel')}
          </Buttons.Primary>
        ) : (
          <Buttons.PrimaryDisabled>
            {t('emailCard.submitButtonLabel')}
          </Buttons.PrimaryDisabled>
        )}
      </Styled.FormInput>
      {invalidEmail && <ErrorMessage error="invalid email" />}
    </Styled.Card>
  );
}
