import styled from 'styled-components';
import colours from 'constants/colours';
import * as Text from 'components/_styles/Text.style';

export const Error = styled('div')`
  align-items: center;
  background-color: ${colours.error[50]};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 20px;
`;

export const ErrorMessage = styled(Text.P2Semibold)`
  color: ${colours.error[800]};
  text-align: center;
`;

export const Resend = styled(Text.P2Semibold)`
  color: ${colours.blue[500]};
  cursor: pointer;
  text-align: center;
`;
