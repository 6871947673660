import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { FastField, Form } from 'formik';
import { Box, FormControl, Grid, Link } from '@mui/material';
import { TextField } from 'formik-material-ui';
import { Trans, useTranslation } from 'react-i18next';

import supportLink from 'helpers/supportLink';

import * as Styled from 'components/pageDemoRegistration/_styles/RegistrationCard.style';
import * as StyledHelp from 'components/pageLogin/_styles/Page.style';
import * as Text from 'components/_styles/Text.style';

import CompanySizeField from 'components/pageDemoRegistration/customFields/CompanySizeField';
import IndustryField from 'components/pageDemoRegistration/customFields/IndustryField';
import LanguageField from 'components/pageDemoRegistration/customFields/LanguageField';
import Loader from 'components/pageECoach/Loader';
import RegistrationErrorCard from 'components/pageDemoRegistration/RegistrationErrorCard';
import RegistrationSuccessCard from 'components/pageDemoRegistration/RegistrationSuccessCard';

export default function RegistrationForm({
  isSubmitting,
  status,
  setFieldValue,
}) {
  const { t, i18n } = useTranslation('pageDemoRegistration');
  const [currentLanguage, setCurrentLanguage] = React.useState(i18n.language);

  useEffect(() => {
    setFieldValue('language', currentLanguage);
  }, [setFieldValue, currentLanguage]);

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value).then(() => {
      setFieldValue('language', value);
      setCurrentLanguage(value);
    });
  };

  if (status?.success) return <RegistrationSuccessCard t={t} />;
  if (status?.error) return <RegistrationErrorCard t={t} />;
  return (
    <>
      <Styled.HeaderBlock>
        <Text.H2>{t('Try it now!')}</Text.H2>
        <Text.P3>
          {t(
            'To tailor your demo experience, we would like to learn a bit more about you.'
          )}
        </Text.P3>
      </Styled.HeaderBlock>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FastField
              key={currentLanguage}
              component={TextField}
              name="first_name"
              label={t('First Name')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FastField
              key={currentLanguage}
              component={TextField}
              name="last_name"
              label={t('Last Name')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FastField
              key={currentLanguage}
              component={TextField}
              name="email"
              label={t('Email')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LanguageField
              key={currentLanguage}
              t={t}
              currentLanguage={currentLanguage}
              handleLanguageChange={handleLanguageChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <IndustryField
              key={currentLanguage}
              currentLanguage={currentLanguage}
              t={t}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FastField
              key={currentLanguage}
              component={TextField}
              name={`additional_infos.job_title`}
              label={t('Job Title')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FastField
              key={currentLanguage}
              component={TextField}
              name={`additional_infos.experience_in_years`}
              label={t('Experience In Years')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FastField
              key={currentLanguage}
              component={TextField}
              name={`additional_infos.company_name`}
              label={t('Company Name')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <CompanySizeField currentLanguage={currentLanguage} t={t} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FastField
              key={currentLanguage}
              component={TextField}
              name="additional_infos.issues"
              label={t('Issues')}
              placeholder={t(
                'Team morale, interpersonal conflicts, difficult conversations, reaching set goals, etc.'
              )}
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Grid
            container
            flexDirection="column"
            alignItems="end"
            justifyContent="flex-end"
            sx={{ mt: 4 }}
          >
            <Styled.Submit disabled={isSubmitting} type="submit">
              {!isSubmitting ? t('Submit') : t('Submitting... Please wait...')}
              {isSubmitting && <Loader inline />}
            </Styled.Submit>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{ mt: 4 }}
            >
              <Box sx={{ pt: 1, pl: 2 }}>
                <StyledHelp.HelpLink
                  target="_blank"
                  href={supportLink(currentLanguage)}
                >
                  {t('Need help')}
                </StyledHelp.HelpLink>
              </Box>
              <Box sx={{ pt: 1, pl: 2 }}>
                <Text.P1>
                  <Trans
                    i18nKey="privacyPolicy"
                    components={[
                      <Link
                        key="0"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.nurau.com/privacy-policy"
                      />,
                    ]}
                    ns="pageDemoRegistration"
                  />
                </Text.P1>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

RegistrationForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  status: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
};
