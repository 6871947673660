import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import buttonCalendarSubscriptionEN from 'components/buttonCalendarSubscription/_translations/en.json';
import buttonCalendarSubscriptionFR from 'components/buttonCalendarSubscription/_translations/fr.json';
import buttonBookingEN from 'components/buttonBooking/_translations/en.json';
import buttonBookingFR from 'components/buttonBooking/_translations/fr.json';
import buttonFeedbackEN from 'components/buttonFeedback/_translations/en.json';
import buttonFeedbackFR from 'components/buttonFeedback/_translations/fr.json';
import buttonJoinLobbyEN from 'components/buttonJoinLobby/_translations/en.json';
import buttonJoinLobbyFR from 'components/buttonJoinLobby/_translations/fr.json';
import buttonOwlEN from 'components/buttonOwl/_translations/en.json';
import buttonOwlFR from 'components/buttonOwl/_translations/fr.json';
import navbarEN from 'components/navbar/_translations/en.json';
import navbarFR from 'components/navbar/_translations/fr.json';
import page404EN from 'components/page404/_translations/en.json';
import page404FR from 'components/page404/_translations/fr.json';
import pageCalendarEN from 'components/pageCalendar/_translations/en.json';
import pageCalendarFR from 'components/pageCalendar/_translations/fr.json';
import pageEcoachEN from 'components/pageECoach/_translations/en.json';
import pageEcoachFR from 'components/pageECoach/_translations/fr.json';
import pageEvaluationEN from 'components/pageEvaluation/_translations/en.json';
import pageEvaluationFR from 'components/pageEvaluation/_translations/fr.json';
import pageJourneyIdEN from 'components/pageJourneyId/_translations/en.json';
import pageJourneyIdFR from 'components/pageJourneyId/_translations/fr.json';
import pageJourneyListEN from 'components/pageJourneyList/_translations/en.json';
import pageJourneyListFR from 'components/pageJourneyList/_translations/fr.json';
import pageLayoutEN from 'components/_pageLayout/_translations/en.json';
import pageLayoutFR from 'components/_pageLayout/_translations/fr.json';
import pageLoginEN from 'components/pageLogin/_translations/en.json';
import pageLoginFR from 'components/pageLogin/_translations/fr.json';
import pageOnboardingEN from 'components/pageOnboarding/_translations/en.json';
import pageOnboardingFR from 'components/pageOnboarding/_translations/fr.json';
import pageOrganizationEN from 'components/pageOrganization/_translations/en.json';
import pageOrganizationFR from 'components/pageOrganization/_translations/fr.json';
import pageProfileEN from 'components/pageProfile/_translations/en.json';
import pageProfileFR from 'components/pageProfile/_translations/fr.json';
import pageSlideshowEN from 'components/pageSlideshow/_translations/en.json';
import pageSlideshowFR from 'components/pageSlideshow/_translations/fr.json';
import pageChatroomEN from 'components/pageChatroom/_translations/en.json';
import pageChatroomFR from 'components/pageChatroom/_translations/fr.json';
import progressIndicatorsEN from 'components/progressIndicators/_translations/en.json';
import progressIndicatorsFR from 'components/progressIndicators/_translations/fr.json';
import pageDemoRegistrationEN from 'components/pageDemoRegistration/_translations/en.json';
import pageDemoRegistrationFR from 'components/pageDemoRegistration/_translations/fr.json';

const resources = {
  en: {
    buttonCalendarSubscription: buttonCalendarSubscriptionEN,
    buttonBooking: buttonBookingEN,
    buttonFeedback: buttonFeedbackEN,
    buttonJoinLobby: buttonJoinLobbyEN,
    buttonOwl: buttonOwlEN,
    navbar: navbarEN,
    page404: page404EN,
    pageCalendar: pageCalendarEN,
    pageEcoach: pageEcoachEN,
    pageEvaluation: pageEvaluationEN,
    pageJourneyId: pageJourneyIdEN,
    pageJourneyList: pageJourneyListEN,
    pageLayout: pageLayoutEN,
    pageLogin: pageLoginEN,
    pageOnboarding: pageOnboardingEN,
    pageOrganization: pageOrganizationEN,
    pageProfile: pageProfileEN,
    pageSlideshow: pageSlideshowEN,
    progressIndicators: progressIndicatorsEN,
    pageChatroom: pageChatroomEN,
    pageDemoRegistration: pageDemoRegistrationEN,
  },
  fr: {
    buttonCalendarSubscription: buttonCalendarSubscriptionFR,
    buttonBooking: buttonBookingFR,
    buttonFeedback: buttonFeedbackFR,
    buttonJoinLobby: buttonJoinLobbyFR,
    buttonOwl: buttonOwlFR,
    navbar: navbarFR,
    page404: page404FR,
    pageCalendar: pageCalendarFR,
    pageEcoach: pageEcoachFR,
    pageEvaluation: pageEvaluationFR,
    pageJourneyId: pageJourneyIdFR,
    pageJourneyList: pageJourneyListFR,
    pageLayout: pageLayoutFR,
    pageLogin: pageLoginFR,
    pageOnboarding: pageOnboardingFR,
    pageOrganization: pageOrganizationFR,
    pageProfile: pageProfileFR,
    pageSlideshow: pageSlideshowFR,
    progressIndicators: progressIndicatorsFR,
    pageChatroom: pageChatroomFR,
    pageDemoRegistration: pageDemoRegistrationFR,
  },
};

i18n
  .use(LanguageDetector) // use LanguageDetector
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    debug: process.env.REACT_APP_DEBUG === 'true',

    supportedLngs: ['en', 'fr'],
    fallbackLng: 'en',
    load: 'languageOnly',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      useSuspense: true,
    },
  });

export default i18n;
