import React from 'react';
import { useTranslation } from 'react-i18next';
import StyledMarkdown from 'components/_pageLayout/StyledMarkdown';

import * as Page from 'components/pageECoach/_styles/ChatArea.style';

// components

import ECoachItemListCard from 'components/pageECoach/cardTemplates/ECoachItemListCard';
import ECoachResponse from 'components/pageECoach/cardTemplates/ECoachResponse';
import Loader from 'components/pageECoach/Loader';
import SuggestionListItem from 'components/pageECoach/suggestions/SuggestionListItem';
import SuggestionItemDetails from 'components/pageECoach/suggestions/SuggestionItemDetails';
import SuggestionDocuments from 'components/pageECoach/suggestions/SuggestionDocuments';

// context
import { useSessionContext } from 'contexts/ECoachSessionContext';

// hooks & helpers
import routesConfig from 'constants/routesConfig.json';
import { useEcoachItemsConsumer } from 'hooks/useEcoachItemsConsumer';
import { SessionProgressChoices } from 'components/pageECoach/utils';

// styles
import * as InputStyle from 'components/pageECoach/_styles/MessageInputs.style';
import * as Text from 'components/_styles/Text.style';

export default function SuggestionsCard() {
  const { t } = useTranslation('pageEcoach');

  const { session, updateProgress } = useSessionContext();
  const { context, items, selectedItem, selectItem, ragSummary } =
    useEcoachItemsConsumer(session.id, routesConfig.ECOACH.SUGGESTION_ITEMS_WS);

  return (
    <Page.ECoachSection>
      <ECoachResponse
        status={items.status}
        loader={<Loader type={'action plan'} />}
      >
        <>
          {items.data && items.data.length > 0 ? (
            <ECoachItemListCard
              items={items.data}
              selectedItem={selectedItem}
              selectItem={selectItem}
              ListItemComponent={SuggestionListItem}
              ItemDetailsComponent={SuggestionItemDetails}
              listTitle={t('Ecoach Suggestions')}
            />
          ) : (
            <>
              <Text.CaptionSemiboldCap>
                {t('convoPrep.Based on your company documents')}
              </Text.CaptionSemiboldCap>
              <StyledMarkdown content={ragSummary.data} />
            </>
          )}
          {context.data?.documents?.length > 0 && (
            <SuggestionDocuments
              documentList={context.data?.documents}
              session={session.id}
            />
          )}
          {session.progress < SessionProgressChoices.CONVERSATION_PREP && (
            <InputStyle.ButtonsColumn>
              <InputStyle.PromptButtonDark
                onClick={() => {
                  updateProgress(
                    session.id,
                    SessionProgressChoices.CONVERSATION_PREP
                  );
                }}
              >
                {t('Help me prepare for a conversation')}
              </InputStyle.PromptButtonDark>
            </InputStyle.ButtonsColumn>
          )}
        </>
      </ECoachResponse>
    </Page.ECoachSection>
  );
}
